<template>
  <div class="mainwrap userlog" v-loading="loading">
    <div class="title">
      會員票夾消費明細
      <span>
        <el-button type="primary" plain @click="goblack">返回</el-button>
      </span>
    </div>

    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="paymentCounter" label="使用廳別">
        </el-table-column>
        <el-table-column prop="paymentAmount" label="消費金額">
        </el-table-column>
        <!-- <el-table-column prop="paymentAmount" label="消費明細">
        </el-table-column> -->
        <el-table-column prop="detail" label="消費明細">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.detail"
              type="primary"
              @click="showDis(scope.row.detail)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="详情" :visible.sync="dialogTableVisible">
      <el-table v-if="dialogTableVisible" :data="openList">
        <el-table-column
          v-for="(val, i, index) in openList[0]"
          :key="index"
          :prop="i"
        >
          <template slot="header">
            {{ i }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";
import QS from "qs";
export default {
  data() {
    return {
      loading: false,
      searchForm: {
        couponId: "",
      },
      tableData: [],
      isSinglePage: false,
      dialogTableVisible: false,
      openList: [],
    };
  },
  created() {
    let queryObj = this.$route.query || {};
    console.log(queryObj);
    if (queryObj.id) {
      this.searchForm.couponId = queryObj.id;
      this.getPaymentRecordList();
    }
  },
  methods: {
    showDis(e) {
      this.openList = [];
      this.dialogTableVisible = true;
      this.openList = JSON.parse(e);
    },
    getPaymentRecordList() {
      let params = {
        couponId: this.searchForm.couponId,
      };
      this.loading = true;
      api.couponWalletPaymentRecord(this.searchForm.couponId).then((res) => {
        if (res.systemCode === 200) {
          if (res.data) {
            this.tableData.push(res.data);
          }
        }
        this.loading = false;
      });
    },
    onSearch() {
      this.currentPage = 1;
      this.getPaymentRecordList();
    },
    reSet() {
      console.log(2);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPaymentRecordList();
    },
    // 返回
    goblack() {
      this.$router.push({ path: "/cms/memberWallet" });
    },
  },
};
</script>

<style lang="scss" scoped>
.userlog {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    /deep/ .el-form-item__label {
      font-weight: 600;
    }
  }
  .minwidth {
    display: inline-block;
    min-width: 150px;
  }
}
</style>
