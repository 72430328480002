import { getRequest,getStrRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 列表
export const couponWallet=(params)=>{
    return getRequest(API.couponWallet,params);
}
// 列表
export const couponWalletPaymentRecord=(params)=>{
    return getRequest(API.couponWalletPaymentRecord+'/'+params);
}