import { getRequest,getStrRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 獲取列表
export const getPaymentMemberList=(params)=>{
    return getStrRequest(API.paymentMemberList,params);
}
// 明細列表
export const getPaymentRecordList=(params)=>{
    return getStrRequest(API.paymentRecordList,params);
}
// 區域列表
export const getRegionList=(params)=>{
    return getRequest(API.regionList,params);
}
// 店別列表
export const getCounterList=(params)=>{
    return getRequest(API.shopList,params);
}
// 品牌別列表
export const getBrandBieList=(params)=>{
    return getRequest(API.brandBieList,params);
}
// 導出
export const paymentExportReport=(params)=>{
    return postRequest(API.paymentExportReport,params);
}
// 導出消費會員
export const exportPaymentMemberReport=(params)=>{
    return postRequest(API.exportPaymentMemberReport,params);
}