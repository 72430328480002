<template>
  <div class="mainwrap userlog" v-loading="loading">
      <div class="title">
          會員消費明細
          <span>
            <el-button type="primary" plain @click="goblack">返回</el-button>
          </span>
      </div>
      <div class="searchform">
        <el-form
            label-width="90px"
            :inline="true"
            :model="searchForm"
            
            >
            <!-- <el-form-item label="會員ID:">
              <span class="minwidth">{{searchForm.MemberId}}</span>
            </el-form-item> -->
            <el-form-item label="會員姓名:">
              <span class="minwidth">{{searchForm.Name}}</span>
            </el-form-item>
            <!-- <el-form-item label="消費店別：">
              <el-input v-model="searchForm.Shop" placeholder="請輸入消費店別"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询</el-button>
              <el-button @click="reSet">重置</el-button>
            </el-form-item> -->
        </el-form>
      </div>

      <div class="tablewrap">
        <el-table :data="tableData" style="width: 100%" stripe>
            <el-table-column prop="paymentTime" label="消費日期"> </el-table-column>
            <el-table-column prop="paymentCounter" label="消費店別"> </el-table-column>
            <el-table-column prop="paymentAmount" label="消費金額"> </el-table-column>
            <el-table-column prop="payType" label="支付別"> 
              <template slot-scope="scope">
                <el-button v-if="scope.row.payType" type="primary" @click="showDis(scope.row.payType)">查看</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="coupons" label="優惠券"> 
              <template slot-scope="scope">
                <el-button v-if="scope.row.coupons" type="primary" @click="showDis(scope.row.coupons)">查看</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="detail" label="消費明細">
              <template slot-scope="scope">
                <el-button v-if="scope.row.detail" type="primary" @click="showDis(scope.row.detail)">查看</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="point" label="點數折抵"> </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
          <el-pagination
              background
              :hide-on-single-page="isSinglePage"
              layout="prev, pager, next,jumper"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="tableDataTotal"
          >
          </el-pagination>
      </div>
      <el-dialog title="详情" :visible.sync="dialogTableVisible">
        <el-table v-if="dialogTableVisible" :data="openList">
          <el-table-column v-for="(val,i,index) in openList[0]" :key="index" :prop="i">
            <template slot="header">
             {{i}}
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";
import QS from "qs";
export default {
  data(){
    return{
      loading:false,
      searchForm:{
        Shop:"",
        MemberId:"",
        Name:"",
        CounterId:"",
        PaymentAmountEnd:"",
        PaymentAmountStart:"",
        PaymentNumStart:"",
        PaymentNumEnd:"",
        PaymentTimeEnd:"",
        PaymentTimeStart:"",
        PaymentAmountType:"",
        BrandId: ""
      },
      tableData:[],
      isSinglePage:false,
      dialogTableVisible:false,
      pageSize:10,
      currentPage:1,
      tableDataTotal:0,
      openList: []
    }
  },
  created() {
    let queryObj = this.$route.query || {};
    console.log(queryObj);
    if (queryObj.id) {
      this.searchForm.MemberId=queryObj.id;
      this.searchForm.Name=queryObj.name;
      this.searchForm.CounterId=queryObj.CounterId;
      this.searchForm.BrandId=queryObj.BrandId;
      this.searchForm.PaymentAmountEnd=queryObj.PaymentAmountEnd;
      this.searchForm.PaymentAmountStart=queryObj.PaymentAmountStart;
      this.searchForm.PaymentNumStart=queryObj.PaymentNumStart;
      this.searchForm.PaymentNumEnd=queryObj.PaymentNumEnd;
      this.searchForm.PaymentTimeEnd=queryObj.PaymentTimeEnd;
      this.searchForm.PaymentTimeStart=queryObj.PaymentTimeStart;
      this.searchForm.PaymentAmountType=queryObj.PaymentAmountType;
      this.getPaymentRecordList();
    }
  },
  methods:{
    showDis(e){
      this.openList = []
      this.dialogTableVisible = true
      this.openList = JSON.parse(e)
    },
    getPaymentRecordList(){
      let params={
        MemberId:this.searchForm.MemberId,
        PaymentAmountEnd:this.searchForm.PaymentAmountEnd,
        CounterId:this.searchForm.CounterId,
        BrandId:this.searchForm.BrandId,
        PaymentAmountStart:this.searchForm.PaymentAmountStart,
        PaymentNumStart:this.searchForm.PaymentNumStart,
        PaymentNumEnd:this.searchForm.PaymentNumEnd,
        PaymentTimeEnd:this.searchForm.PaymentTimeEnd,
        PaymentTimeStart:this.searchForm.PaymentTimeStart,
        PaymentAmountType:this.searchForm.PaymentAmountType,
        SkipCount:(this.currentPage-1)*this.pageSize,
        MaxResultCount:this.pageSize
      }
      // 拼接參數
      let paramsStr="";
      for (let key in params){
          if(params[key]!=="" && key!=="CounterId"){
              paramsStr+=`${key}=${params[key]}&`;
          }
      }
      // 拼接CounterId:[1,2] => CounterId=1&CounterId=2
      if(params.CounterId!==""){
          paramsStr+=QS.stringify({CounterId: params.CounterId}, { arrayFormat: 'repeat' })
      }else{
          paramsStr=paramsStr.slice(0,-1);
      }
      this.loading=true;
      api.getPaymentRecordList(paramsStr).then(res=>{
        if(res.systemCode===200){
              this.tableData=res.data.items;
              this.tableDataTotal=res.data.totalCount;
          }
          this.loading=false;
      })
    },
    onSearch(){
      this.currentPage=1;
      this.getPaymentRecordList();
    },
    reSet(){
      console.log(2);
    },
    handleCurrentChange(val){
      this.currentPage=val;
      this.getPaymentRecordList();
    },
    // 返回
    goblack(){
      this.$router.push({path:"/cms/consumptionlog"});
    }
  }
}
</script>

<style lang="scss" scoped>
.userlog{
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    /deep/ .el-form-item__label{
      font-weight: 600;
    }
  }
  .minwidth{
    display: inline-block;
    min-width: 150px;
  }
}
</style>